/* Reboot CSS */
* {
  margin: 0;
  padding: 0;
}

body,
html {
  display: flex;
  flex-direction: column;
  height:100vh;
  font-family: "Roboto", sans-serif;
  scrollbar-width: thin;
  padding-right: 0 !important;
}

.page-content-wrapper {
  margin-top: 50px;
  margin-bottom: 58px;
}

input:required,
textarea:required {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

input:invalid,
textarea:invalid {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.no-boxshadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}


// Estilos Jhonattan

.bg-navbar{
  background-color: rgb(43, 96, 171);
}

.bg-sidebar{
  background-color: rgb(33, 65, 112);
}

.card img {
  width: 150px;
  height: 75%;

}

.infoLista{
  color: rgb(43, 96, 171);
}

.infoCard{
  line-height: 1rem;
}

.tamano:hover{
  cursor: pointer;
    opacity: 0.8;
}

.infoPrecio{
  line-height: 5px;
}

.foto1{
  position: relative;
  left: 2rem;
  width: 150px;
  height: 75%;
}

.foto2{
  position: relative;
  right: 2rem;
  width: 150px;
  height: 75%;
}

.selectInput{
  width: 95%;
  margin-top: 40px;
}

.cantidadProducto{
  width: 95%;
}

.estrellasDiv{
  height: 38px;
  display: flex;
  align-items:center;
}

.tituloHeader{
  margin: auto;
  margin-left: 20px;
}

.precioLine{
  text-decoration: line-through;
}

.tituloNav{
  font-family: Russo One;
}

.usuarioNav{
  font-family: Sora;
}

.sidebarFont{
  font-family: Quicksand;
}

.mostrarSidebar{
  display: none;
}

.subfondo{
  background-color: rgb(45, 104, 185);
  min-height: 70vh;
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
}

.space{
  top:13px;
  position: relative;
}

.form-control:focus{
  box-shadow: none;
}

.btn-nuevo{
  background: rgb(86, 159, 255);
}

.btn-editar{
  background-color: rgb(255, 208, 52);
}

.btn-eliminar{
  background-color: rgb(255, 130, 130);
}

.focus-none:focus{
  box-shadow: none;
}

@media screen and (max-width: 1212px) {
  .mostrarSidebar{
    display: block;
  }
  .navbarMenu{
    display: none;
  }
}