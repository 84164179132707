@import "../scss/colors";

.wrapper{
  display: flex;
  flex-direction: column;
  height:100vh;
  width: 100vw;
  scrollbar-width: thin;
  padding-right: 0 !important;
  background-image: url('../assets/fondo.jpg');
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}

.login-wrapper {
  position: relative;
  min-height: 98vh;
  overflow-y: hidden;
  overflow-x: hidden;
}

.register-form {
  position: relative;
  z-index: 1;
}
.register-form .form-group {
  position: relative;
  z-index: 1;
}
.register-form span {
  color: $primary;
  font-size: 12px;
  margin-right: 7px;
}
.register-form form > .form-group > label {
  position: absolute;
  text-align: center;
  z-index: 100;
  border-radius: 50%;
  left: 0;
  font-size: 16px;
  margin-bottom: 0;
  color: $primary;
  top: 26px;
}
.register-form .form-control {
  border-radius: 0;
  border: 0;
  color: $primary;
  font-size: 13px;
  background-color: $transparent;
  height: 40px;
  padding: 8px 8px 8px 24px;
  border-bottom: 1px solid $primaryOpacity;
}

.register-form .form-control input:focus {
  background: $transparent;
  border: none;
  border-bottom: solid 0.5px $primary;
  width: 100%;
  margin: 5% 0 10%;
}

.register-form .form-control::-webkit-input-placeholder {
  color: $primary;
  opacity: 0.5;
}
.register-form .form-control::-moz-placeholder {
  color: $primary;
  opacity: 0.5;
}
.register-form .form-control:-ms-input-placeholder {
  color: $primary;
  opacity: 0.5;
}
.register-form .form-control::-ms-input-placeholder {
  color: $primary;
  opacity: 0.5;
}
.register-form .form-control::placeholder {
  color: $primary;
  opacity: 0.5;
}

.register-form .btn-submit {
  border-color: $primaryOpacity;
  background-color: $white;
  color: $primary;
}

.register-form .btn-submit:hover {
  color: $primary;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.error {
  color: red;
}
