body {
    font-family: sans-serif;
  }
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width:  calc(166px + 1rem);
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {

    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 250px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(50% + 1rem);
    position: absolute;
    top: 75%;
    left: 30.5%;
    z-index: 100;
    border-bottom: 1px solid #999;
    background-color: #ffffffff;
    border-radius: 3%;
  }
  .suggestions li {
    padding: 0.5rem;
  }
